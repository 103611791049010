<template>
  <div>
    <b-card>
      <h1>
        Importuj plik CSV z booksy (spowoduje rozesłanie SMSów)
      </h1>
    </b-card>
    <b-card>
      <div>
        <h2>Aktualna liczba SMSów: {{ business.sms_balance }}
        </h2>
        <label for="file">
          Plik do importu:
        </label>
        <br>
        <input
          id="file"
          type="file"
        >
      </div>
    </b-card>
    <b-card>
      <button
        v-if="IsLoading === false"
        class="btn btn-primary"
        @click="Import()"
      >
        Importuj
      </button>
      <b-spinner v-else />
      <a
        target="_blank"
        :href="buyLink"
        class="btn btn-primary ml-2"
      >
        Kup smsy
      </a>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      IsLoading: false,
      buyLink: '',
      business: {},
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      document.title = 'Import - Pickmdoe'

      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      axios.get(`${process.env.VUE_APP_API_URL}user`, config)
        .then(res => {
          this.buyLink = res.data.business.buySmsLink
          this.business = res.data.business
        })
    },
    Import() {
      const formData = new FormData()
      formData.append('file', document.querySelector('#file').files[0])
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}`, 'Content-Type': 'multipart/form-data' } }
      axios.post(`${process.env.VUE_APP_API_URL}cvs-sms-send-cost`, formData, config)
        .then(res => {
          if (res.data.can_buy) {
            Swal.fire({
              title: `Przewidywana cena SMSów to: ${res.data.cost} PLN`,
              text: 'Czy chcesz kontynuować?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Tak',
              cancelButtonText: 'Nie',
            }).then(result => {
              if (result.value) {
                this.ImportCustomers()
              } else {
                Swal.fire({
                  title: 'Anulowano',
                  type: 'info',
                  icon: 'info',
                })
              }
            })
          } else {
            Swal.fire('Nie masz wystarczającej ilości smsów.', '', 'error')
          }
        })
    },
    ImportCustomers() {
      const formData = new FormData()
      formData.append('file', document.querySelector('#file').files[0])
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}`, 'Content-Type': 'multipart/form-data' } }
      axios.post(`${process.env.VUE_APP_API_URL}cvs-sms-send`, formData, config)
        .then(() => {
          Swal.fire('Sukces', 'Pomyślnie zlecono wysłanie smsów', 'success')
          this.init()
        }).catch(err => {
          if (err.response.data.error === 'Nie masz wystarczającej ilości smsów') {
            Swal.fire('Nie masz wystarczającej ilości smsów.', '', 'error')
          }
          Swal.fire('Coś poszło nie tak', '', 'error')
        })
    },
  },
}
</script>
